<template>
  <el-dialog
  v-dialogDrag
    title="竞价求购详情"
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    :before-close="handleClose"
    width="900px"
  >
    <div class="auditCon">  
      <p class="auditNote">审核意见：{{ form.note }}</p>
   </div>
    <div class="viewDetails">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">商品分类：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.className }}</p>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12" v-for="(item,index) of form.specificationsName" :key="index">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">商品属性：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ item.label }}</p>
            </el-col>
          </el-row>
        </el-col>
       
         <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">公示时间：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.showTime | DateFormate }}</p>
            </el-col>
          </el-row>
        </el-col>
         <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">开拍时间：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.startTime | DateFormate }}</p>
            </el-col>
          </el-row>
        </el-col>
         <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">结拍时间：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.endTime | DateFormate }}</p>
            </el-col>
          </el-row>
        </el-col>
          <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">起拍总价：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.startPrice }}元</p>
            </el-col>
          </el-row>
        </el-col>
         <el-col :span="12" v-if="form.quantity">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">数量：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.quantity }}</p>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">重量：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.hangWeight }}{{form.unit}}</p>
            </el-col>
          </el-row>
        </el-col>
          <!-- <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">磅差：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.poundsPoor }}{{form.unit}}</p>
            </el-col>
          </el-row>
        </el-col>
          <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">是否开启集采：</p>
            </el-col> 
            <el-col :span="16">
              <p class="text">{{ form.phaseType=='T'?'开启':'不开' }}</p>
            </el-col>
          </el-row>
        </el-col> -->
          <!-- <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">交易方式：</p>
            </el-col> 
            <el-col :span="16">
              <p class="text">{{ form.tradeWay=='A'?'整批':'分销' }}</p>
            </el-col>
          </el-row>
        </el-col> -->
         <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">企业名称：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.hangEnterpriseName }}</p>
            </el-col>
          </el-row>
        </el-col>
        <!-- <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">法人手机号：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.enterprisePhone }}</p>
            </el-col>
          </el-row>
        </el-col> -->
         <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">状态：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.hangResourcesStatus | hangStatusFormate }}</p>
            </el-col>
          </el-row>
        </el-col>
          <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">是否流拍：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.isFlow ?'是':'否'}}</p>
            </el-col>
          </el-row>
        </el-col>
    
          <!-- <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">竞价中标价：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.offerPrice}}</p>
            </el-col>
          </el-row>
        </el-col> -->
       
          <!-- <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">到期时间：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.qxTime | DateFormate }}</p>
            </el-col>
          </el-row>
        </el-col> -->
        <!-- <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">类型：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.hangResourcesType | guaStatusFormate }}</p>
            </el-col>
          </el-row>
        </el-col> -->
         <!-- <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">生产日期：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.tradeDay | DateFormateInt }}</p>
            </el-col>
          </el-row>
        </el-col> -->
         <!-- <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">生产厂家：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.manufacturer }}</p>
            </el-col>
          </el-row>
        </el-col> -->
          <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">交收仓库：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.sname }}</p>
            </el-col>
          </el-row>
        </el-col>
         <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">业务负责人：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.businessName }}</p>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">联系电话：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.businessPhone  }}</p>
            </el-col>
          </el-row>
        </el-col>
          <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">预计入库时间：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.putWarehousTime | DateFormateDay }}</p>
            </el-col>
          </el-row>
        </el-col>
          <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">开票期限：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.openTicketTime | DateFormateDay }}</p>
            </el-col>
          </el-row>
        </el-col>
        <!-- <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">是否可议价：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.bargainingStatus | isholdFormate }}</p>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">图片：</p>
            </el-col>
            <el-col :span="16">
              <img class="proPictureImg" v-if="form.img !== '--'" :src="form.img" />
              <p v-else>--</p>
            </el-col>
          </el-row>
        </el-col> -->
         <!-- <el-col :span="24" v-if="priceStep.length!=0">
          <el-row :gutter="20">
            <el-col :span="4">
              <p class="title">阶梯价：</p>
            </el-col>
            <el-col :span="20">
              <span v-for="( item,index) of priceStep" :key="index" class="text">{{ item.lowWeight }}-{{ item.highWeight }}{{item.unit }} &nbsp;{{ item.price }}元&nbsp;<i v-if="index<priceStep.length-1" class="setStyle">;</i>&nbsp;&nbsp;</span>
            </el-col>
          </el-row>
        </el-col> -->
      <!-- </el-row> -->
     
      <!-- <el-row :gutter="20">
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">交易商名称：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.senterpriseName }}</p>
            </el-col>
          </el-row>
        </el-col>
      </el-row> -->
 
        <!-- <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">总价格：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ form.zjPrice }}元</p>
            </el-col>
          </el-row>
        </el-col> -->
      <!-- <el-row :gutter="20"> -->
        <el-col :span="12">
          <el-row :gutter="20">
            <el-col :span="8">
              <p class="title">履约订金：</p>
            </el-col>
            <el-col :span="16">
              <p class="text">{{ deposit }}元</p>
            </el-col>
          </el-row>
        </el-col>
         <el-col :span="24">
          <el-row :gutter="20">
            <el-col :span="4">
              <p class="title">特殊要求描述：</p>
            </el-col>
            <el-col :span="20">
              <p class="text">{{ form.manufacturer?form.manufacturer:'--' }}</p>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
export default {
  data() {
    return {
      dialogVisible: false,
      form: {
        // warehousId: null,
        // price: null
      },
      priceStep:[]
    };
  },
  computed: {
    deposit(){
      let zjPriceCalc = util.floatMul(
        this.form.price,
        this.form.hangWeight
      );
       return util.floatMul(zjPriceCalc,this.form.marginRate)
    }
  },
  methods: {
    showDialog(row) {
      this.dialogVisible = true;
      this.getDetails(row.hangResourcesId,row.pickResourcesId);

    },
    getDetails(id,pickResourcesId) {
      let that = this
      protocolFwd.param_queryResourceDetails.param.hangResourcesId = id;
      protocolFwd.param_queryResourceDetails.param.pickResourcesId = pickResourcesId;
      http
        .postFront(protocolFwd.param_queryResourceDetails)
        .then(response => {
          const { code, message, value } = response.data;
          if (code == 0) {
            value.specificationsName = this.attributeTrans(value.specificationsName)
            this.form = value;
            //  if(value.tradeWay=='P'){
            //    that.getStepPrice(value.hangResourcesId)
            //  }
            for (let i in this.form) {
              if (!this.form[i]&&this.form[i]!=0) {
                this.form[i] = "--";
              }
            }
          } else {
            this.$EL_MESSAGE(message);
          }
        });
    },
    getStepPrice(id) {
       protocolFwd.param_queryPhaseHangPrice.param.hangResourceId = id;
      http
        .postFront(protocolFwd.param_queryPhaseHangPrice)
        .then(response => {
          const { code, message, value } = response.data;
          if (code == 0) {
           this.priceStep = value
          } else {
            this.$EL_MESSAGE(message);
          }
        });
    },
    handleClose() {
      this.dialogVisible = false;
    }
  }
};
</script>
<style scoped>
.viewDetails {
  line-height: 40px;
}
.viewDetails .title {
  text-align: right;
}
.proPictureImg {
  max-width: 80%;
}
.auditCon{
  position: absolute;
  left:20%;
  top:20px;
}
.setStyle{
  font-style: normal;
}
</style>