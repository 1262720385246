<template>
  <!-- 竞价求购 -->
  <div class="subPage" id="biddingBuy">
    <div class="subPageTitle">
      <div class="subPageTitleText">
        <p>竞价采购委托</p>
      </div>
      <div class="addButton">
        <el-button
          v-if="right.add"
          type="danger"
          :disabled="isNewOrder"
          :loading="isNewOrder"
          @click="addNewOrder"
          >新增竞价单</el-button
        >
      </div>
    </div>
    <el-row :gutter="10">
      <el-col :span="24" class="selectHeadBox">
        <el-row :gutter="10">
          <el-col :span="6">
            <el-col :span="6">
              <p class="searchItemTitle">商品分类</p>
            </el-col>
            <el-col :span="18">
              <el-cascader
                class="width-100"
                v-model="selectCategoryOptions"
                :options="categoryOptions"
                :props="categoryProps"
              ></el-cascader>
            </el-col>
          </el-col>
          <!-- <el-col :span="2"><p class="headText">品名</p></el-col>
          <el-col :span="4">
            <el-select v-model="select2.value" placeholder="全部" clearable @change="changeSelect2">
              <el-option
                v-for="item in select2.options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-col> -->
          <!-- <el-col :span="4">
            <el-col :span="6">
              <p class="searchItemTitle">仓库</p>
            </el-col>
            <el-col :span="18">
              <el-select v-model="select3.value" placeholder="全部" @change="changeSelect3">
                <el-option
                  v-for="item in select3.storageIdOptions"
                  :key="item.storageId"
                  :label="item.name"
                  :value="item.storageId">
                </el-option>
              </el-select>
            </el-col>
          </el-col> -->
          <el-col :span="6">
            <el-button type="danger" @click="getDatas()">搜索</el-button>
            <el-button @click="resetSearch()">重置</el-button>
          </el-col>
        </el-row>
        <el-col :span="24">
          <el-menu
            :default-active="activeIndex"
            type="card"
            class="el-menu-demo"
            mode="horizontal"
            @select="handleSelect"
            text-color="#000"
            active-text-color="rgb(210, 29, 39)"
          >
            <el-menu-item index="0">全部</el-menu-item>
            <el-menu-item index="1">已上架</el-menu-item>
            <el-menu-item index="2">待上架</el-menu-item>
            <el-menu-item index="3">已撤销</el-menu-item>
            <el-menu-item index="4">未通过</el-menu-item>
          </el-menu>
        </el-col>
        <!-- <el-row class="selectHeadBox">
          <el-col :span="4" class="allSelectBox">
            <el-checkbox v-model="selectAllTableData">全选</el-checkbox>
          </el-col>
          <el-col :span="4">
            <el-button type="warning" size="mini" :disabled='isdeleatOrders' :loading="isdeleatOrders" @click="deleatOrders">批量下架</el-button>
          </el-col>
        </el-row> -->
        <el-table
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%"
        >
          <el-table-column label="商品分类">
            <template slot-scope="scope">{{ scope.row.className }}</template>
          </el-table-column>
          <el-table-column prop="specificationsName" label="商品属性">
            <template slot-scope="scope">{{
              scope.row.specificationsName | firstAttributeTransformate
            }}</template>
          </el-table-column>
          <el-table-column prop="hangResourcesType" label="挂单方式">
            <template slot-scope="scope">{{
              scope.row.hangResourcesType | typeFormate
            }}</template>
          </el-table-column>
          <el-table-column prop="price" label="基准单价">
            <template slot-scope="scope"
              >{{ scope.row.price }}元/{{
                scope.row.unit ? scope.row.unit : "--"
              }}</template
            >
          </el-table-column>
          <el-table-column prop="hangWeight" min-width="50" label="重量">
            <template slot-scope="scope"
              >{{ scope.row.hangWeight }}{{ scope.row.unit }}</template
            >
          </el-table-column>
          <el-table-column prop="startPrice" label="起拍总价">
            <template slot-scope="scope">{{ scope.row.startPrice }}元</template>
          </el-table-column>
          <el-table-column prop="addPrice" label="加价幅度"> </el-table-column>
          <el-table-column prop="isFlow" label="是否流拍">
            <template slot-scope="scope">
              <span>{{ scope.row.isFlow | biddingIsFlowFormate }}</span>
            </template>
          </el-table-column>
          <el-table-column label="资源状态">
            <template slot-scope="scope">{{
              scope.row.auditStatus | auditStatusFormate
            }}</template>
          </el-table-column>
          <el-table-column label="挂单状态">
            <template slot-scope="scope">{{
              scope.row.hangResourcesStatus | hangStatusFormate
            }}</template>
          </el-table-column>
          <el-table-column label="挂单时间">
            <template slot-scope="scope">{{
              scope.row.hangCreateTime | DateFormate
            }}</template>
          </el-table-column>
          <el-table-column label="操作" width="160">
            <template slot-scope="scope">
              <el-button
                @click.native.prevent="howViewDialog(scope.$index, scope.row)"
                size="mini"
                >详情</el-button
              >
              <el-button
                v-if="right.cancel"
                :disabled="
                  scope.row.hangResourcesStatus == 'U' ||
                    scope.row.auditStatus == 'F'
                "
                @click.native.prevent="deleteRow(scope.$index, scope.row)"
                type="danger"
                size="mini"
                >撤销</el-button
              >
              <el-button
                style="margin-top:5px;"
                v-if="
                  scope.row.type != 'X' &&
                    right.hang &&
                    scope.row.auditStatus == 'Y'
                "
                @click="payDialog(scope.row)"
                size="mini"
                >支付履约订金</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="current"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="totalPage"
          ></el-pagination>
        </div>
      </el-col>
    </el-row>
    <!-- 新增 -->
    <addDialog ref="addDialog" @reLoad="getDatas()" />
    <!-- 查看 -->
    <viewDialog ref="viewDialog" />
    <!-- 支付履约订金 -->
    <payDialog ref="payDialog" @reLoad="getDatas()" />
  </div>
</template>
<script>
import addBiddingBuy from "./addBiddingBuy";
import viewDialog from "./viewBiddingBuy";
import payDialog from "./payDialog";
import { mapGetter, mapGetters } from "vuex";
export default {
  components: {
    addDialog: addBiddingBuy,
    viewDialog: viewDialog,
    payDialog
  },
  data() {
    return {
      activeIndex: "0",
      selectActiveIndex: "0",
      // 仓库
      select3: {
        // options: [{
        //   value: '选项1',
        //   label: '仓库1'
        // }, {
        //   value: '选项2',
        //   label: '仓库2'
        // }, {
        //   value: '选项3',
        //   label: '仓库3'
        // }],
        storageIdOptions: [],
        value: ""
      },
      isNewOrder: false, // 新增求购单按钮状态,
      isdeleatOrders: false, // 批量删除按钮状态
      tableData: [], // http://114.116.96.237:38808/apigateway/precacheservice/doc.html#/default/capacity-controlller/queryBiddingBuyerResourceUsingPOST
      multipleSelection: [],
      selectAllTableData: false,
      //分页
      current: 1,
      pageSize: 10,
      totalPage: null,
      // 品种
      categoryOptions: [],
      categoryProps: {
        label: "className",
        value: "classId",
        checkStrictly: false
      },
      selectCategoryOptions: [],
      right: {
        add: false,
        cancel: false,
        hang: false
      },
      searchForm: {
        statusStr: null,
        orderStatus: null
      }
    };
  },
  computed: {
    ...mapGetters(["sessionInfoGetter"])
  },
  watch: {
    selectAllTableData(val, oldVal) {
      if (val) {
        if (this.tableData) {
          this.tableData.forEach(row => {
            this.$refs.multipleTable.toggleRowSelection(row, true);
          });
        }
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    selectCategoryOptions(val, oldVal) {
      console.log(val);
      this.getDatas();
    }
  },
  mounted() {
    this.getDatas();
    this.getCommodityCategory();
    // this.getStorageIdOptions()
    this.getRights();
  },
  methods: {
    getRights() {
      this.loadRight().then(loadResult => {
        if (loadResult == "loadRightSuccess") {
          this.right.add = this.isHasRight("tc-trade-hangBuyPresaleResources");
          this.right.cancel = this.isHasRight("tc-trade-undoHangResources");
          this.right.hang = this.isHasRight("tc-trade-payHangOrder");
        }
      });
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getDatas();
    },
    handleCurrentChange(val) {
      this.current = val;
      this.getDatas();
    },
    resetSearch() {
      this.selectActiveIndex = "0";
      this.selectCategoryOptions = [];
      this.select3.value = "";
      this.searchForm = {
        statusStr: null,
        orderStatus: null
      };
      this.selectCategoryOptions = [];
      this.getDatas();
    },
    getDatas() {
      protocolFwd.param_queryActionResource.param = {
        page: this.current - 1,
        size: this.pageSize,
        sort: [{ property: "hangResourcesId", direction: "DESC" }],
        hangFirmId: sessionStorage.getItem("userFirmId"),
        commodityId:
          this.selectCategoryOptions.length > 0
            ? this.selectCategoryOptions[this.selectCategoryOptions.length - 1]
            : null,
        auditStatus: this.searchForm.statusStr,
        hangResourcesStatus: this.searchForm.orderStatus,
        hangResourceType: ["Q"],
        sourceId: "J"
      };
      http.postFront(protocolFwd.param_queryActionResource).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          this.tableData = value.content;
          this.totalPage = value.totalElements;
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    payDialog(row) {
      this.$refs.payDialog.showDialog(row);
    },
    // 商品品种+品名
    getCommodityCategory() {
      http.getRes(protocolFwd.param_queryVarieties).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          let categoryArr = value;
          this.categoryOptions = this.clearChildren(categoryArr);
        } else {
          this.categoryOptions = [];
          this.$EL_MESSAGE(message);
        }
      });
    },
    clearChildren(Arr) {
      Arr.forEach(element => {
        if (element && element.children) {
          if (element.children.length == 0) {
            delete element["children"];
          } else {
            this.clearChildren(element.children);
          }
        }
      });
      return Arr;
    },
    // 查询仓库字典表
    getStorageIdOptions() {
      http.postFront(protocolFwd.param_getStorage).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          this.select3.storageIdOptions = value;
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    handleSelect(key, keyPath) {
      this.selectActiveIndex = key;
      if (key === "0") {
        this.searchForm.statusStr = null;
        this.searchForm.orderStatus = null;
      } else if (key === "1") {
        this.searchForm.statusStr = ["T"];
        this.searchForm.orderStatus = ["O", "B", "A"];
      } else if (key === "2") {
        this.searchForm.statusStr = ["Y", "P"];
        this.searchForm.orderStatus = ["O", "B", "A"];
      } else if (key === "3") {
        this.searchForm.statusStr = null;
        this.searchForm.orderStatus = ["U"];
      } else if (key === "4") {
        this.searchForm.statusStr = ["F"];
        this.searchForm.orderStatus = ["O", "B", "A"];
      }
      this.tableData = [];
      this.current = 1;
      this.pageSize = 10;
      this.totalPage = null;
      this.getDatas();
    },
    changeSelect2(val) {
      console.log(val);
    },
    // 选择仓库
    changeSelect3(val) {
      console.log(val);
      this.getDatas();
    },
    addNewOrder() {
      this.isNewOrder = true;
      this.$refs.addDialog.showDialog();
      setTimeout(() => {
        this.isNewOrder = false;
      }, 2000);
    },
    deleatOrders() {
      this.$EL_MESSAGE("暂无此功能");
      this.isdeleatOrders = true;
      setTimeout(() => {
        this.isdeleatOrders = false;
      }, 2000);
    },
    // table 操作触发
    deleteRow(index, data) {
      console.log(index, data);
      this.$confirm("请确认是否撤销该采购委托？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          protocolFwd.param_undoPublish.param.hangResourcesId =
            data.hangResourcesId;
          http.postFront(protocolFwd.param_undoPublish).then(response => {
            const { code, message, value } = response.data;
            if (code == 0) {
              this.$EL_MESSAGE(message);
              this.getDatas();
            } else {
              this.$EL_MESSAGE(message);
            }
          });
        })
        .catch(() => {});
    },
    howViewDialog(index, row) {
      this.$refs.viewDialog.showDialog(row);
    },
    // table 外部控制选中/取消选中调用方法
    toggleSelection(rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    }
  }
};
</script>
<style scoped lang="scss">
.subPage {
  padding: 0 15px;
}
.subPageTitle {
  overflow: hidden;
}
.subPageTitle .subPageTitleText {
  float: left;
  line-height: 40px;
}
.subPageTitle .subPageTitleText p {
  font-size: 16px;
}
.subPageTitle .addButton {
  float: right;
}
.selectHeadBox {
  margin: 10px 0;
  /deep/ .el-table thead th {
    background-color: rgba(5, 64, 106, 1);
    color: #fff;
  }
  .allSelectBox {
    line-height: 30px;
    margin-left: 20px;
  }
}
.headText {
  line-height: 40px;
  text-align: center;
}
.pagination {
  margin-top: 15px;
}
.searchItemTitle {
  line-height: 40px;
}
</style>
