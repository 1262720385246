<template>
  <div>
    <!-- 新增竞价求购 -->
    <el-dialog
      v-dialogDrag
      title="新增竞价求购单"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      width="900px"
    >
      <div>
        <el-form ref="form" :rules="rules" :model="form" label-position="left">
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="公示开始时间" prop="showTime" label-width="120px">
                <el-date-picker
                  class="width-100"
                  v-model="form.showTime"
                  type="datetime"
                  placeholder="请选择时间"
                  :picker-options="putWarehousTimePicker"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="竞价开始时间" prop="startTime" label-width="120px">
                <el-date-picker
                  class="width-100"
                  v-model="form.startTime"
                  type="datetime"
                  placeholder="请选择时间"
                  :picker-options="putWarehousTimePicker"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <!-- </el-row>
            <el-row :gutter="20">-->
            <el-col :span="12">
              <el-form-item label="竞价结束时间" prop="endTime" label-width="120px">
                <el-date-picker
                  class="width-100"
                  v-model="form.endTime"
                  type="datetime"
                  placeholder="请选择时间"
                  :picker-options="putWarehousTimePicker"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="商品分类" prop="commodityId" label-width="120px">
                <el-cascader
                  class="width-100"
                  ref="cascader"
                  v-model="form.commodityId"
                  :options="categoryOptions"
                  :props="categoryProps"
                  :show-all-levels="false"
                  @focus="getCommodityCategory"
                ></el-cascader>
              </el-form-item>
            </el-col>
            <el-col :span="12" v-for="(item,index) of gradeIdOptions" :key="index">
              <el-form-item :label="item.name" prop="specificationsName" label-width="120px">
                <el-select class="width-100" v-model="form.specificationsName[index]">
                  <el-option
                    v-for="items in item.children"
                    :key="items.id"
                    :label="items.value"
                    :value="item.value+','+items.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <!-- <el-col :span="12">
              <el-form-item label="生产厂家" prop="manufacturer" label-width="120px">
                <el-input v-model="form.manufacturer"></el-input>
              </el-form-item>
            </el-col>-->
            <el-col :span="12">
              <el-form-item label="仓库" prop="storageId" label-width="120px">
                <el-select class="width-100" v-model="form.storageId" @change="unitChange">
                  <el-option
                    v-for="item in storageIdOptions"
                    :key="item.storageId"
                    :label="item.name"
                    :value="item.storageId"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="挂单数量" prop="quantity" label-width="120px">
                <el-input type="number" v-model="form.quantity"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="挂单重量" prop="weight" label-width="120px">
                <el-input v-model="form.weight"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="重量单位" prop="weightUnitId" label-width="120px">
                <el-select class="width-100" v-model="form.weightUnitId" placeholder="请先选择仓库，再选择重量单位
">
                  <el-option
                    v-for="items in weightUnitOptions"
                    :key="items.weightUnitId"
                    :label="items.name"
                    :value="items.weightUnitId"
                  ></el-option>
                </el-select>
                <!-- <el-input v-model="form.unit"></el-input> -->
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="基准单价" prop="price" label-width="120px">
                <el-input v-model="form.price">
                  <template slot="append">元/{{selectWeightUnit.name}}</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="起拍总价" prop="startPrice" label-width="120px">
                <el-input v-model="form.startPrice">
                  <template slot="append">元</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="减价幅度" prop="addPrice" label-width="120px">
                <!-- <el-select class="width-100" v-model="form.addPrice">
                  <el-option v-for="item in auctionRule" :key="item" :label="item" :value="item"></el-option>
                </el-select> -->
                  <el-input v-model="form.addPrice">
                  <template slot="append">元</template>
                </el-input>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="预计入库时间" prop="putWarehousTime" label-width="120px">
                <el-date-picker
                  class="width-100"
                  v-model="form.putWarehousTime"
                  type="date"
                  :picker-options="putWarehousTimePicker"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="开票期限" prop="openTicketTime" label-width="120px">
                <el-date-picker
                  class="width-100"
                  v-model="form.openTicketTime"
                  type="date"
                  placeholder="请选择日期"
                  :picker-options="putWarehousTimePicker"
                ></el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="业务负责人" prop="businessName" label-width="120px">
                <el-input v-model="form.businessName"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="联系电话" prop="businessPhone" label-width="120px">
                <el-input v-model="form.businessPhone"></el-input>
              </el-form-item>
            </el-col>
             <!-- <el-col :span="12">
              <el-form-item label="交易方式" prop="tradeWay" label-width="120px">
                <el-radio-group v-model="form.tradeWay" size="small">
                  <el-radio label="A">整批</el-radio>
                  <el-radio label="P">分销</el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
             <el-col :span="12" v-if="form.tradeWay=='P'">
             <el-form-item label="最小摘单重量" prop="minPickingWeight" label-width="120px">
                <el-input v-model="form.minPickingWeight"></el-input>
              </el-form-item>
            </el-col> -->
            <el-col :span="24">
              <el-form-item label="特殊要求描述" prop="manufacturer" label-width="120px">
                <el-input v-model="form.manufacturer" placeholder="请您将生产厂家、产地、品牌及其他相关特殊要求写在此处"></el-input>
              </el-form-item>
            </el-col>
            <!-- <el-col :span="12">
            <el-form-item label="备注" prop="memo" label-width="120px">
              <el-input v-model="form.memo"></el-input>
            </el-form-item>
            </el-col>-->
            <!-- <el-col :span="12">
              <el-form-item label="商品图片" prop="image" label-width="120px">
                <el-upload
                  class="avatar-uploader"
                  :action="actionUrl"
                  :show-file-list="false"
                  :on-success="handleAvatarSuccess"
                  :before-upload="beforeAvatarUpload"
                  :data="postData"
                >
                  <img v-if="form.image" :src="form.image" class="avatar" />
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </el-form-item>
            </el-col>-->
          </el-row>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="confirm()">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
    id="d1"
      v-dialogDrag
      title="温馨提示"
      :close-on-click-modal="false"
      :visible.sync="dialogVisible1"
      width="30%"
      :before-close="handledialogVisible1"
    >
      <el-form ref="newForm" :rules="newFormRules" :model="newForm">
        <el-row :gutter="20">
          <el-col :span="24" style="color: red;font-weight: 700;font-size: 18px">
            本委托须冻结履约订金: ￥{{ deposit }}
            <br />请联系财务人员或企业管理员进行支付操作！
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible1=false">取 消</el-button>
        <el-button type="primary" @click="confirmSure()" :loading="isAddLoading">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  created() {
    this.categoryOptions = []
    // this.form.commodityId = null
  },
  data() {
    var checkAmount = (rule, value, callback) => {
      let reg = /^[1-9]\d*$/g;
      // if (value == "") {
      //   callback(new Error("请输入数量"));
      // }
      if (value) {
        if (!reg.test(value)) {
          return callback(new Error("请输入正整数"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    var checkShowTime = (rule, value, callback) => {
      let currentTime = new Date().getTime();
      let newValue = value.getTime();
      if (value == "") {
        callback(new Error("请选择公示开始时间"));
      } else if (newValue <= currentTime) {
        return callback(new Error("公示时间应晚于当前时间"));
      } else {
        callback();
      }
    };
    var checkStartTime = (rule, value, callback) => {
      const that = this;
      if (!that.form.showTime) {
        return callback(new Error("请先选择公示时间"));
      } else if (value == "") {
        callback(new Error("请选择开始时间"));
      } else if (that.form.showTime) {
        let currentTime = that.form.showTime.getTime();
        let newValue = value.getTime();
        if (newValue <= currentTime) {
          return callback(new Error("开始时间应晚于公示时间"));
        } else {
          callback();
        }
      }
    };
    var checkEndTime = (rule, value, callback) => {
      const that = this;
      if (!that.form.showTime) {
        return callback(new Error("请先选择公示时间"));
      } else if (!that.form.startTime) {
        return callback(new Error("请先选择开始时间"));
      } else if (value == "") {
        callback(new Error("请选择结束时间"));
      } else if (that.form.startTime) {
        let currentTime = that.form.startTime.getTime();
        let newValue = value.getTime();
        if (newValue <= currentTime) {
          return callback(new Error("结束时间应晚于开始时间"));
        } else {
          callback();
        }
      }
    };
    var checkPutWarehousDay = (rule, value, callback) => {
       const that = this;
      if (!that.form.showTime) {
        return callback(new Error("请先选择公示时间"));
      } else if (!that.form.startTime) {
        return callback(new Error("请先选择开始时间"));
      } else if (!that.form.endTime) {
        callback(new Error("请选择结束时间"));
      } else if (value == "") {
        callback(new Error("请选择预计入库时间"));
      } else if (that.form.endTime) {
        let currentTime = that.form.endTime.getTime();
        let newValue = value.getTime();
        if (newValue <= currentTime) {
          return callback(new Error("预计入库时间应晚于竞价结束时间"));
        } else {
          callback();
        }
      }
      // let currentTime = new Date();
      // let yearTime = currentTime.getFullYear();
      // let monTime = currentTime.getMonth() + 1;
      // let dayTime = currentTime.getDate();
      // if (monTime < 10) {
      //   monTime = "0" + monTime;
      // }
      // if (dayTime < 10) {
      //   dayTime = "0" + dayTime;
      // }
      // let str = `${yearTime}${monTime}${dayTime}`;
      // if (value == "") {
      //   return callback(new Error("请选择入库时间"));
      // } else if (Number(value) < Number(str)) {
      //   return callback(new Error("入库时间只能选择今天及以后"));
      // } else {
      //   callback();
      // }
    };
    var checkOpenTicketDay = (rule, value, callback) => {
      const that = this;
      if (!that.form.showTime) {
        return callback(new Error("请先选择公示时间"));
      } else if (!that.form.startTime) {
        return callback(new Error("请先选择开始时间"));
      } else if (!that.form.endTime) {
        callback(new Error("请选择结束时间"));
      } else if (!that.form.putWarehousTime) {
        callback(new Error("请选择预计入库时间"));
      } else if (value == "") {
        callback(new Error("请选择开票期限"));
      } else if (that.form.endTime) {
        let currentTime = that.form.endTime.getTime();
        let currentPutTime = that.form.putWarehousTime.getTime();
        let newValue = value.getTime();
        if (newValue <= currentTime || newValue <= currentPutTime) {
          return callback(
            new Error("开票时间应晚于竞拍结束时间和预计入库时间")
          );
        } else {
          callback();
        }
      }
    };
    var checkSpecName = (rule, value, callback) => {
      if (
        this.gradeIdOptions.length != 0 &&
        value.length != this.gradeIdOptions.length
      ) {
        return callback(new Error("请选择商品属性"));
      } else {
        callback();
      }
    };
    var checkPutHoldWeight = (rule, value, callback) => {
      let reg = /^\d+(\.\d{0,2})?$/;
      if (value == "") {
        return callback(new Error("请输入重量"));
      } else if (!reg.test(value) || Number(value) <= 0) {
        return callback(new Error("重量必须是正数且不能超过两位小数"));
      } else {
        callback();
      }
    };
    var checkPrice = (rule, value, callback) => {
      let reg = /^\d+(\.\d{0,2})?$/;
      if (value == "") {
        return callback(new Error("请输入起拍总价"));
      } else if (!reg.test(value) || Number(value) <= 0) {
        return callback(new Error("起拍总价必须是正数且不能超过两位小数"));
      } else {
        callback();
      }
    };
    var checkAmount = (rule, value, callback) => {
      let reg = /^[1-9]\d*$/g;
      if (value) {
        if (!reg.test(value)) {
          return callback(new Error("请输入正整数"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    var checkMinPickWeight = (rule, value, callback) => {
      let reg = /^\d+(\.\d{0,2})?$/;
      if (this.form.tradeWay == "P"&&!value) {
        return callback(new Error("请输入最小摘单重量"));
      } else if (this.form.tradeWay == "P"&&!this.form.weight) {
        return callback(new Error("请输入挂单重量"));
      } else if (this.form.tradeWay == "P"&&!reg.test(value) || Number(value) <= 0) {
        return callback(new Error("最小摘单重量必须是正数且不能超过两位小数"));
      } else if (this.form.tradeWay == "P"&& Number(value) >= Number(this.form.weight)) {
        return callback(new Error("最小摘单重量必须是小于挂单重量"));
      }else {
        callback();
      }
    };
    return {
      dialogVisible1: false,
      dialogVisible: false,
      form: {
        auctionStyle: 1, //竞拍方式 1-普通，2-跌价式，3-大宗竞拍
        addPrice: null, // 加价幅度
        contactName: "", // 联系人
        contactPhone: "", // 联系电话
        endTime: null, // 竞拍结束时间
        // image: "", // 商品图片
        //    memo: "", // 备注
        openTicketTime: null, // 开票期限
        manufacturer: "", // 生产厂家
        price: null, // 单价
        quantity: null, // 数量
        showTime: null, // 公示开始时间
        startPrice: null, // 起拍总价
        startTime: null, // 竞拍开始时间
        weight: null, // 重量
        weightUnitId: null, // 参持仓单位
        businessName: null,
        businessPhone: null, 
        commodityId: null, // 商品id
        specificationsId: null, //规格id 1-1
        specificationsName: [], //规格名称{颜色：红色}json形式
        storageId: null,
        putWarehousTime: null,
        tradeWay:'A',
        minPickingWeight:null,//分销的话最小摘单重量
        // code: null,
        // marginPassWord: null
      },
      weightUnitOptions: [],
      selectWeightUnit: {},
      newForm: {
        code: null,
        marginPassWord: null,
      },
      rules: {
        commodityId: [
          { required: true, message: "请选择商品品种", trigger: "blur" },
        ],
        specificationsName: [
          { required: false, message: "请选择商品属性", trigger: "change" },
          { validator: checkSpecName, trigger: "blur" },
        ],
        showTime: [
          { required: true, message: "请选择公示开始时间", trigger: "change" },
          { validator: checkShowTime, trigger: "blur" },
        ], // 公示开始时间
        startTime: [
          { required: true, message: "请选择竞拍开始时间", trigger: "change" },
          { validator: checkStartTime, trigger: "blur" },
        ], // 竞拍开始时间
        endTime: [
          { required: true, message: "请选择竞拍结束时间", trigger: "change" },
          { validator: checkEndTime, trigger: "blur" },
        ], // 竞拍结束时间
        manufacturer: [
          { required: true, message: "请输入特殊要求描述", trigger: "change" }
        ],// 生产厂家
        quantity: [
          { required: false, message: "请输入数量", trigger: "change" },
          { validator: checkAmount, trigger: "blur" },
        ], // 数量
        weight: [
          {
            required: true,
            message: "请输入挂单重量",
            trigger: "change",
          },
          {
            validator: checkPutHoldWeight,
            trigger: "change",
          },
        ], // 重量

        price: [
          { required: true, message: "请输入基准单价", trigger: "change" },
          { validator: checkAmount, trigger: "change" },
        ], // 单价
        weightUnitId: [
          { required: true, message: "请选择商品单位", trigger: "blur" },
        ], // 单价
        startPrice: [
          { required: true, message: "请输入起拍总价", trigger: "change" },
          { validator: checkPrice, trigger: "change" },
        ], // 起拍总价
        addPrice: [
          { required: true, message: "请输入减价幅度", trigger: "change" },
          { validator: checkAmount, trigger: "change" },
        ], // 加价幅度
        putWarehousTime: [
          { required: true, message: "请选择预计入库时间", trigger: "change" },
           { validator: checkPutWarehousDay, trigger: "change" }
        ], // 开票期限
        openTicketTime: [
          { required: true, message: "请选择开票期限", trigger: "change" },
          { validator: checkOpenTicketDay, trigger: "change" },
        ], // 开票期限
        storageId: [
          { required: true, message: "请选择仓库", trigger: "change" },
        ], // 联系人
        contactName: [
          { required: true, message: "请选择联系人", trigger: "change" },
        ], // 联系人
        contactPhone: [
          { required: true, message: "请选择联系电话", trigger: "change" },
        ], // 联系电话
        // memo: [{ required: true, message: "请选择备注", trigger: "change" }], // 备注
        // image: [
        //   { required: true, message: "请选择商品图片", trigger: "change" }
        // ], // 商品图片

        businessName: [
          { required: true, message: "请输入业务负责人", trigger: "change" },
        ],
        businessPhone: [
          { required: true, message: "请输入联系电话", trigger: "change" },
          // {
          //   pattern: /^1[3456789]\d{9}$/,
          //   message: "手机号格式不正确",
          //   trigger: "blur"
          // }
        ],
        tradeWay: [
          { required: true, message: "请选择交易方式", trigger: "change" },
        ],
        minPickingWeight: [
          { required: false, message: "请输入最小摘单重量", trigger: "change" },
          { validator: checkMinPickWeight, trigger: "change" },
        ],
      },
      newFormRules: {
        // code: [{ required: true, message: "请输入短信验证码", trigger: "change" }],
        // marginPassWord: [{ required: true, message: "请输入资金密码", trigger: "change" }],
      },
      putWarehousTimePicker: {
        disabledDate: (time) => {
          return time.getTime() < Date.now() - 8.64e7;
        },
      },
      categoryProps: {
        label: "className",
        value: "classId",
      },
      gradeIdProps: {
        label: "value",
        value: "value",
      },
      categoryOptions: [],
      gradeIdOptions: [],
      packagIdOptions: [],

      isAddLoading: false,
      actionUrl: window.g.APP_USER_PATH_UPLOAD,
      postData: {
        uid: this.$store.state.common.sessionInfo.firmId,
        deleteStatus: 0,
        width: null,
        height: null,
        size: null,
      },
      auctionRule: [],
      storageIdOptions: [],
      validButtonText: "发送验证码",
      validBtnDisabled: false,
      validBtnTimer: null,
      newcommodityId: null,
      currentCommodityId: null, // 用于加价幅度获取
    };
  },
  computed: {
    ...mapGetters(["getMarginRateGetter"]),
    deposit() {
      let currentPrice = util.floatMul(this.form.price, this.form.weight);
      return util.floatMul(currentPrice, this.getMarginRateGetter.marginRate);
    },
  },
  watch: {
    "form.commodityId": {
      handler(val, oldVal) {
        this.getGradeIdOptions();
        this.form.price = null;
        this.auctionRule = [];
        this.gradeIdOptions = [];
        this.form.specificationsName = [];
        this.form.addPrice = null;
        if (val) {
          // this.param_queryAuctionRule();
        }
      },
    },
    "form.weightUnitId": {
      handler(val, oldVal) {
        let that = this;
        this.form.price = null;
        this.auctionRule = [];
        this.form.addPrice = null;
        if (val) {
          // this.param_queryAuctionRule();
        }
        for (let i = 0; i < this.weightUnitOptions.length; i++) {
          if (val == that.weightUnitOptions[i].weightUnitId) {
            that.selectWeightUnit = that.weightUnitOptions[i];
          }
        }
      },
    },
  },
  methods: {
    // 根据当前所选仓库查询单位
    unitChange(e) {
      this.weightUnitOptions = [];
      this.form.weightUnitId = ''      
      this.selectWeightUnit.name = ''
      protocolFwd.param_unitOrWarehouse.param.storageId = e;
      http.postFront(protocolFwd.param_unitOrWarehouse).then((response) => {
        console.log(response);
        let { value, code, message } = response.data;
        if (code == 0) {
          this.weightUnitOptions = value.content;
        }
      });
    },
    handledialogVisible1() {
      this.dialogVisible1 = false;
    },
    setTime(e, vueThis) {
      let _this = this;
      e--;
      vueThis.validButtonText = e + "s";
      if (e > 0) {
        vueThis.validBtnTimer = setTimeout(function () {
          _this.setTime(e, vueThis);
        }, 1000);
      } else {
        vueThis.validButtonText = "发送验证码";
        vueThis.validBtnDisabled = false;
      }
    },
    sendValidNo() {
      protocol.param_authn_margin_sendCaptcha.param.id = 30;
      http
        .postFront(protocol.param_authn_margin_sendCaptcha)
        .then((response) => {
          this.$EL_MESSAGE(response.data.message);
          const that = this;
          this.validBtnDisabled = true;
          this.setTime(60, that);
        });
    },
    handleAvatarSuccess(res, file) {
      this.form.image = res.value;
    },
    beforeAvatarUpload(file) {
      const testmsg = /^image\/(jpeg|png|jpg)$/.test(file.type);
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!testmsg) {
        this.$EL_MESSAGE.error("上传图片格式不对!");
        return;
      }
      if (!isLt2M) {
        this.$EL_MESSAGE.error("上传图片大小不能超过 2MB!");
      }
      return testmsg && isLt2M;
    },
    showDialog(row) {
      const _cascader = this.$refs.cascader
      if (_cascader) {
        _cascader.$refs.panel.checkedValue = []
        _cascader.$refs.panel.activePath = [] 
        _cascader.$refs.panel.syncActivePath()
      }
      this.dialogVisible = true;
      this.categoryOptions = []
      this.form.commodityId = null
      // this.getCommodityCategory();
      this.getfindWeightUnit();
      this.getStorageIdOptions();
    },
    getfindWeightUnit() {
      http.postFront(protocolFwd.param_findWeightUnit).then((response) => {
        const { code, message, value } = response.data;
        if (code == 0) {
          // this.weightUnitOptions = value.content;
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    // 商品品种+品名
    getCommodityCategory() {
      http.getRes(protocolFwd.param_queryVarieties).then((response) => {
        const { code, message, value } = response.data;
        if (code == 0) {
          console.log('商品分类', value)
          this.deleteCategoryOptionsLast(value);
          this.categoryOptions = value;
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    deleteCategoryOptionsLast(data) {
      for (const item of data) {
        if (item.children.length !== 0) {
          this.deleteCategoryOptionsLast(item.children);
        } else {
          delete item.children;
        }
      }
    },
    clearChildren(Arr) {
      Arr.forEach((element) => {
        if (element && element.children) {
          if (element.children.length == 0) {
            delete element["children"];
          } else {
            this.clearChildren(element.children);
          }
        }
      });
      return Arr;
    },
    getGradeIdOptions() {
      if (Array.isArray(this.form.commodityId)) {
        this.newcommodityId = this.form.commodityId[
          this.form.commodityId.length - 1
        ];
      } else {
        this.newcommodityId = this.form.commodityId;
      }
      protocolFwd.param_queryTemplateGroup.param.goodsGroupClassId = this.newcommodityId;
      http.postFront(protocolFwd.param_queryTemplateGroup).then((response) => {
        const { code, message, value } = response.data;
        if (code == 0) {
          if (value.length != 0) {
            let goodsGroupCommonSpec = JSON.parse(value[0].paramTemplate);
            for (let i = 0; i < goodsGroupCommonSpec.length; i++) {
              goodsGroupCommonSpec[i].id = value[0].id;
              goodsGroupCommonSpec[i].value = goodsGroupCommonSpec[i].name;
              goodsGroupCommonSpec[i].children =
                goodsGroupCommonSpec[i].content;
            }
            this.gradeIdOptions = goodsGroupCommonSpec;
          } else {
            this.gradeIdOptions = [];
          }
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    // 仓库列表
    getStorageIdOptions() {
      http.postFront(protocolFwd.param_getStorage).then((response) => {
        const { code, message, value } = response.data;
        if (code == 0) {
          this.storageIdOptions = value;
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    // 查询基准单价与减价幅度
    param_queryAuctionRule() {
      protocolFwd.param_queryAuctionRule.param = {
        commodityId: this.newcommodityId ? this.newcommodityId : null,
        unitId: this.form.weightUnitId ? this.form.weightUnitId : null,
      };
      if (
        !protocolFwd.param_queryAuctionRule.param.commodityId ||
        !this.form.weightUnitId
      ) {
        return;
      }
      http.postFront(protocolFwd.param_queryAuctionRule).then((response) => {
        const { code, message, value } = response.data;
        if (code == 0) {
          if (value.length != 0) {
            let arr = [];
            arr[0] = value[0].addPriceOne;
            arr[1] = value[0].addPriceTwo;
            arr[2] = value[0].addPriceThree;
            arr[3] = value[0].addPriceFour;
            arr[4] = value[0].addPriceFive;
            this.form.price = value[0].basePrice;
            this.auctionRule = arr;
          }
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    confirm() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.dialogVisible1 = true;
        }
      });
    },
    confirmSure() {
      this.$refs["newForm"].validate((valid) => {
        if (valid) {
          let specificationsNames = {}; // 对象
          if (this.form.specificationsName.length != 0) {
            for (let i = 0; i < this.form.specificationsName.length; i++) {
              let currentObj = {};
              let str = this.form.specificationsName[i];
              let str1 = str.split(",");
              currentObj[str1[0]] = str1[1];
              specificationsNames = Object.assign(
                specificationsNames,
                currentObj
              );
            }
          }
          if (JSON.stringify(specificationsNames) == "{}") {
            specificationsNames = null;
          }

          // protocolNJP.param_marginBuy.param = this.form;
          protocolNJP.param_marginBuy.param.showTime = Number(
            this.form.showTime
          ); // 公示开始时间
          protocolNJP.param_marginBuy.param.startTime = Number(
            this.form.startTime
          ); // 竞拍开始时间
          protocolNJP.param_marginBuy.param.endTime = Number(this.form.endTime); // 竞拍结束时间
          protocolNJP.param_marginBuy.param.manufacturer = this.form.manufacturer; // 生产厂家
          protocolNJP.param_marginBuy.param.commodityId = Number(
            this.newcommodityId
          ); // 商品品种id
          protocolNJP.param_marginBuy.param.specificationsName = specificationsNames; // 规格
          protocolNJP.param_marginBuy.param.quantity = Number(
            this.form.quantity
          ); // 数量
          protocolNJP.param_marginBuy.param.weight = Number(this.form.weight); // 重量
          protocolNJP.param_marginBuy.param.weightUnitId = this.form.weightUnitId; // 重量
          protocolNJP.param_marginBuy.param.poundsPoor = Number(
            this.form.poundsPoor
          ); // 磅差
          protocolNJP.param_marginBuy.param.price = Number(this.form.price); // 单价
          protocolNJP.param_marginBuy.param.startPrice = Number(
            this.form.startPrice
          ); // 起拍总价
          protocolNJP.param_marginBuy.param.addPrice = Number(
            this.form.addPrice
          ); // 加价幅度
          protocolNJP.param_marginBuy.param.openTicketTime = Number(
            this.form.openTicketTime
          ); // 开票期限
          protocolNJP.param_marginBuy.param.contactName = this.form.contactName; // 联系人
          protocolNJP.param_marginBuy.param.contactPhone = this.form.contactPhone; // 联系电话
          // protocolNJP.param_marginBuy.param.memo = this.form.memo// 备注
          // protocolNJP.param_marginBuy.param.image = this.form.image// 商品图片
          protocolNJP.param_marginBuy.param.businessName = this.form.businessName; // 商品图片
          protocolNJP.param_marginBuy.param.businessPhone = this.form.businessPhone; // 商品图片
          protocolNJP.param_marginBuy.param.tradeWay = this.form.tradeWay; // 商品图片
          if(this.form.tradeWay=='P'){
            protocolNJP.param_marginBuy.param.minPickingWeight = this.form.minPickingWeight;
          }else{
            protocolNJP.param_marginBuy.param.minPickingWeight = this.form.weight
          }
          protocolNJP.param_marginBuy.param.storageId = this.form.storageId; // 商品图片
          protocolNJP.param_marginBuy.param.putWarehousTime = Number(
            this.form.putWarehousTime
          ); // 商品图片
          protocolNJP.param_marginBuy.param.auctionStyle = 1;
          this.isAddLoading = true;
          http
            .postNewJP(protocolNJP.param_marginBuy)
            .then((response) => {
              const { code, message, value } = response.data;
              this.isAddLoading = false;
              if (code == 0) {
                this.dialogVisible = false;
                this.dialogVisible1 = false;
                this.$EL_MESSAGE("提交成功！待后台审核成功后方可发布");
                this.$refs["form"].resetFields();
                this.$emit("reLoad");
              } else {
                this.$EL_MESSAGE(message);
              }
            })
            .catch(() => {
              this.isAddLoading = false;
            });
        }
      });
    },
    handleClose() {
      this.form = {
        auctionStyle: 1, //竞拍方式 1-普通，2-跌价式，3-大宗竞拍
        addPrice: null, // 加价幅度
        contactName: "", // 联系人
        contactPhone: "", // 联系电话
        endTime: null, // 竞拍结束时间
        // image: "", // 商品图片
        //    memo: "", // 备注
        openTicketTime: null, // 开票期限
        manufacturer: "", // 生产厂家
        price: null, // 单价
        quantity: null, // 数量
        showTime: null, // 公示开始时间
        startPrice: null, // 起拍总价
        startTime: null, // 竞拍开始时间
        weight: null, // 重量
        weightUnitId: null, // 参持仓单位
        businessName: null,
        businessPhone: null,
        commodityId: null, // 商品id
        specificationsId: null, //规格id 1-1
        specificationsName: [], //规格名称{颜色：红色}json形式
        storageId: null,
        putWarehousTime: null,
        tradeWay:'A',
        minPickingWeight:null,//分销的话最小摘单重量
        // code: null,
        // marginPassWord: null
      };
      this.selectWeightUnit = {};
      //  this.newForm = {
      //     code: null,
      //   marginPassWord: null
      // }
      this.$nextTick(() => {
        this.$refs["form"].clearValidate();
        // this.$refs["newForm"].clearValidate();
      });
      this.weightUnitOptions = [];
      this.categoryOptions = []
      this.dialogVisible = false;
      this.dialogVisible1 = false;
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ #d1 .el-dialog__title {
  color: red;
  font-size: 18px;
  font-weight: 700;
}
.tip {
  color: #cf4640;
  position: absolute;
  top: 9px;
  left: 66px;
}
</style>